import Vue from "vue";
import "../public/reset.css";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// 配置 Axios
axios.defaults.baseURL = ""; // 设置基础URL
axios.defaults.timeout = 10000; // 设置请求超时时间为10秒
// 将 Axios 添加到 Vue 原型中，以便于在组件中访问
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(Element);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
