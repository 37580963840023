<template>
  <div class="container">
    <div class="phone-box"> 华翔国际物流24小服务 15013175132</div>
    <div class="nav-box">
      <div class="logo-box">
        <img src="@/assets/logo.jpg" style="border-radius: 50%;" />
      </div>
      <ul class="sec-box">
        <li class="active sec-item">网站首页</li>
        <li class="sec-item" :class="{ active: item.active }" v-for="(item, index) in navData" :key="index"
          @mouseenter="showSlider(index)" @mouseleave="hideSlider" @click="scrollTo(index)">{{ item.name }}
          <div v-show="currentIndex == index && index != 2 && index != 3" class="slider" @click="scrollTo(index)">{{
            item.sec }}
          </div>
        </li>
        <li class="sec-item" @click="gotoContact">查询轨迹</li>
      </ul>
      <div class="btn-box" @click="scrollTo(2)">
        联系我们
      </div>
    </div>
    <div class="swiper-box">
      <el-carousel height="430px" :loop="true" :interval="3000" :indicator-position="'none'">
        <el-carousel-item v-for="(item, index) in items" :key="index">
          <img :src="item.url" alt="华翔优势" title="华翔优势" width="100%" height="100%">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="wel-box">
      <h1 style="margin-bottom: 30px;">欢迎使用华翔国际物流</h1>
      <div class="welcome flex">
        <el-input v-model="keyword" placeholder="您可以输入单号进行查询轨迹" style="width: 400px;"></el-input>
        <div class="btn" @click="handleSearch">查询轨迹</div>
        <a href="https://www.17track.net/?utm_medium=logistics&utm_source=华翔物流  " target="_blank" >
          <img src="../assets/17log.png" alt="" style="height: 40px;width: 220px;">
        </a>
      </div>
      <!-- </form> -->
    </div>
    <div class="pro-box" id="model_1">
      <div class="pro-title">
        <h2>
          核心渠道
        </h2>
      </div>
      <div class="swiper-box1">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item) in cropList" :key="item.url">
            <div class="crop-list">
              <div class="columnar-bar">
                <span class="columnar"></span>
              </div>
              <div class="columnar-content">
                <img :src="item.url" alt="" style="width:300px;display: block;object-fit: fill;">
                <div class="columnar-title">{{ item.desc }}</div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>


        </swiper>
      </div>
    </div>
    <div class="desc-box" id="model_0">
      <div class="left-box">
        <h2>公司简介</h2>
        <hr />
        <p style="letter-spacing: 2px;">
          公司成立于2023年4月1日，是一家拥有高素质、经验丰富的专业团队。
          公司秉承专业、高效、安全的服务理念，华翔致力于为客户提供优质的国际物流解决方案，业务范围涵盖中国至澳大利亚、加拿大、美国空海运服务，可满足不同客户多样化的物流需求。凭借可靠的渠道和良好的口碑，已与众多知名企业建立长期稳定的合作关系。

        </p>
      </div>
      <div class="right-box">
        <el-carousel height="300px" :interval="3000" :indicator-position="'none'">
          <el-carousel-item v-for="(item, index) in swiperList" :key="index">
            <img :src="item.url" style="width:400px;height: 300px;" alt="华翔优势" title="华翔优势" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="nav-box-contact" id="model_2">
      <div class="left-box">
        <div class="nav-title">
          联系我们
        </div>
        <div class="nav-content">
          <ul class="clearfix">
            <li v-for="item in navData2" :key="item.name" style="display: flex;align-items: center;">
              <div style="margin-right: 6%;width: 100px;">{{ item.name }}</div>
              <div style="flex: 1;">{{ item.value }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="right-box">
        <img src="../assets/images/kefu2.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      height: '400',
      items: [
        {
          url: require('../assets/banner1.jpg')
        },
        {
          url: require('../assets/banner2.jpg')
        }
      ],
      navData: [
        {
          name: '关于华翔',
          active: false,
          sec: '企业简介'
        },
        {
          name: '核心渠道',
          active: false,
          sec: '核心渠道'
        },
        {
          name: '联系我们',
          active: false,
          sec: ''
        },
        {
          name: '华翔WMS',
          active: false,
          sec: ''
        }
      ],
      currentIndex: null,
      swiperList: [
        {
          url: require('../assets/swiper1.jpg'),
          title: '核心渠道1',
          desc: '南航直飞LAX/JFK/ORD，每周固定航班，时效5-8天，可以走服装、鞋子、包包个人自用普货，适合TK、Etsy等有虚仓发货的卖家，末端USPS派送。'
        },
        {
          url: require('../assets/swiper2.jpg'),
          title: '核心渠道2',
          desc: '国际航班直达，时效1-2天，可以走服装、鞋子、包包个人自用普货，适合有虚仓发货的卖家，末端USPS派送。'
        },
        {
          url: require('../assets/swiper3.jpg'),
          title: '核心渠道3',
          desc: '国际航班直达，时效1-2天，可以走服装、鞋子、包包个人自用普货，适合有虚仓发货的卖家，末端USPS派送。'
        },
        {
          url: require('../assets/swiper4.jpg'),
          title: '核心渠道4',
          desc: '国际航班直达，时效1-2天，可以走服装、鞋子、包包个人自用普货，适合有虚仓发货的卖家，末端USPS派送。'
        },
        {
          url: require('../assets/swiper5.jpg'),
          title: '核心渠道5',
          desc: '国际航班直达，时效1-2天，可以走服装、鞋子、包包个人自用普货，适合有虚仓发货的卖家，末端USPS派送。'
        },
        {
          url: require('../assets/swiper6.jpg'),
          title: '核心渠道6',
          desc: '国际航班直达，时效1-2天，可以走服装、鞋子、包包个人自用普货，适合有虚仓发货的卖家，末端USPS派送。'
        }
      ],
      target: 0,
      navData2: [
        {
          name: '联系电话',
          value: '15013175132'
        },
        {
          name: '企业QQ',
          value: '550394478'
        },
        {
          name: '投诉电话',
          value: '15013175132'
        },
        {
          name: '公司地址',
          value: '广州市白云区机场路1630号'
        },

      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 0,
        freeMode: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true,
      },
      cropList: [
        {
          url: require('../assets/swiper1.jpg'),
          title: '核心渠道1',
          desc: '加拿大空运小包'
        },
        {
          url: require('../assets/swiper2.jpg'),
          title: '核心渠道2',
          desc: '澳大利亚海运小包'
        },
        {
          url: require('../assets/swiper3.jpg'),
          title: '核心渠道3',
          desc: '美国经济线'
        },
        {
          url: require('../assets/swiper4.jpg'),
          title: '核心渠道4',
          desc: '加拿大海运小包'
        },
        {
          url: require('../assets/swiper5.jpg'),
          title: '核心渠道5',
          desc: '澳大利亚空运小包'
        },
        {
          url: require('../assets/swiper7.png'),
          title: '核心渠道6',
          desc: '美国虚拟仓'
        },
        {
          url: require('../assets/swiper6.jpg'),
          title: '',
          desc: '全部'
        }
      ],
      keyword:''
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted() {
    // this.swiper.slideTo(3, 1000, false)

  },
  methods: {
    gotoContact() {
      window.open('https://t.17track.net/zh-cn')
    },
    showSlider(index) {
      this.navData[index].active = true;
      this.navData.forEach((item, i) => {
        if (i !== index) {
          item.active = false;
        }
      })
      this.currentIndex = index;
    },
    hideSlider() {
      this.currentIndex = null;
      this.navData.forEach(item => {
        item.active = false;
      })
    },
    scrollTo(type) {
      this.$nextTick(() => {
        if (type == 3) {
          console.log(type)
          window.open('http://1.12.254.111:8082/login.htm')
        } else {
          // 1,通过形参获取当前需要的dom
          let targetbox = document.getElementById('model_' + type);
          // 2,获取该dom距离页头的高度
          this.target = targetbox.offsetTop;
          // 3,执行页面跳转
          document.documentElement.scrollTop = this.target;
        }
      })
    },

    handleSearch() {
      window.open(`/track?keyword=${this.keyword}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: center;
}

.container {
  .phone-box {
    height: 50px;
    background: #f7f7f7;
    padding: 0 100px;
    color: #000;
    box-sizing: border-box;
    line-height: 50px;
    font-size: 16px;
  }

  .nav-box {
    height: 75px;
    background-color: #fff;

    padding: 0 100px;
    box-sizing: border-box;
    display: flex;

    .logo-box {
      height: 100%;
      width: 55px;
      display: flex;
      align-items: center;
      margin-right: 20px;

      img {
        width: 55px;
        height: 55px;
        display: block;
        object-fit: fill;
      }
    }

    .sec-box {
      height: 75px;
      flex: 1;
      display: flex;
      line-height: 75px;
      font-size: 16px;

      .sec-item {
        margin-right: 30px;
        box-sizing: border-box;
        height: 100%;
        position: relative;
        cursor: pointer;

        .slider {
          background-color: #f9f9f9;
          border: 1px solid #ccc;

          border-radius: 4px;
          position: absolute;
          z-index: 10;
          bottom: -50px;
          left: -10px;
          width: 100px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          font-size: 14px;
        }
      }

      .active {
        color: #fe9b00;
        border-bottom: 1px solid #fe9b00;

      }
    }

    .btn-box {
      width: 100px;
      height: 30px;
      display: block;
      text-align: center;
      line-height: 30px;
      background-color: #fe9b00;
      border-radius: 15px;
      margin-top: 20px;
      color: #fff;
      cursor: pointer;
    }
  }


  .swiper-box {
    margin: 0 auto;
    width: 100%;
    height: 430px;
    padding: 0 100px;
    box-sizing: border-box;
  }

  .wel-box {
    height: 190px;
    background: #fff;
    padding: 30px 10%;
    text-align: center;
    box-sizing: border-box;
  }

  .pro-box {
    width: 100%;
    height: 530px;
    background: #fff;
    box-sizing: border-box;
    padding: 50px 100px;

    .pro-title {
      width: 100%;
      height: 50px;
      text-align: center;
      font-size: 22px;
      border-bottom: 1px solid dimgray;
      margin-bottom: 15px;

    }

    .swiper-box1 {
      width: 100%;
    }

  }

  .desc-box {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    padding: 50px 100px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    background: #c93e3e;

    .left-box {
      width: 50%;

      hr {
        border-top: 3px solid #fff;
        margin: 10px 0px;
      }
    }

    .right-box {
      width: 40%;
      height: 300px;
      overflow: hidden;
    }
  }

  .nav-box-contact {
    height: 300px;
    padding: 0 100px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #f7f7f7;

    .left-box {
      width: 50%;

      .nav-title {
        font-size: 18px;
        border-bottom: 1px solid #ccc;
        padding: 20px 0 10px 0;
        font-weight: bold;

        p {
          margin: 0 0 10px;
        }



      }

      .nav-content {
        .clearfix {
          margin-top: 20px;
          font-size: 16px;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }

    .right-box {
      width: 40%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.columnar-title {
  width: 300px;
  margin-top: 10px;
  font-size: 14px;
}

::v-deep .el-input__inner:focus {
  border-color: #fe9b00 !important;
}

.btn {
  height: 40px;
  background-color: #fe9b00;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100px;
  line-height: 40px;
  cursor: pointer;
}
</style>