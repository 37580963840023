<template>
  <div class="container">
    <div class="header">
      <Header />
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';
export default {
  name: 'Index',
  components: {
    Footer,
    Header
  }
}
</script>

<style lang="scss" scoped></style>