<template>
  <div class="container">

    <div class="phone-box"> 华翔国际物流24小服务 15013175132</div>
    <div class="serach-box" >
      <div class="search-left">
        <div  v-show="isEmpty" >
          <div class="search-header">
            <div class="left">
              <div class="sign-icon">
                <i class="el-icon-check"></i>
              </div>
              <div class="text">
                <div class="title" id="copyNode">{{info.trackingNumber}}
                  <i class="el-icon-copy-document" @click="handleCopy"></i>
                </div>
                <p>{{info.businessStatus}}</p>
              </div>
            </div>
            <div class="middle">
              参考号：{{info.referenceNumber}}
            </div>
            <div class="right">
              {{ info.trackDate }} &nbsp;{{info.trackContent}}
            </div>
          </div>
          <div class="search-detail">
            <div class="commonTitle">{{info.trackingNumber}}追踪信息</div>
            <div class="content">
                <ul class="dynamicFiles" v-for="(item,index) in timeAxis" :key="index">
                    <li>{{ item.track_date }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ item.track_content}}</li>
                    <li class="detail">{{ item.track_location }}</li>
                </ul>
            </div>
    
          </div>
        </div>
        <el-empty description="没有查询单号。" v-show="!isEmpty"></el-empty>
      </div>
      <div class="search-right">
        <el-input v-model="keyword" placeholder="您可以输入单号进行查询" type="textarea" style="width: 400px;" clearable></el-input>
        <!-- <div class="btn" >查询</div> -->
         <el-button  icon="el-icon-search" style="margin-top:10px;width: 400px;" @click="handleSearch">查询</el-button>
      </div>
    </div>
   
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>
<script>

import Clipboard from 'clipboard';
import Footer from '../components/Footer.vue';
export default {
  name: 'track',
  components: {
    Footer,
  },
  data() {
    return {
          // 时间轴列表
      timeAxis: [
          {
            state: 1, // 状态： 1:已完成，0:未完成
            time: '今天2024年5月2日 上午10:50:11',
            address: '【镇江转运中心】已发出 下一站【镇江为民中心】'
          },
          {
            state: 0,
            time: '2024年5月2日 上午10:30:10',
            address: '【镇江转运中心】已收入'
          },
          {
            state: 0,
            time: '2024年5月2日 上午10:10:08',
            address: '【江苏省无锡市富民路】已发出 下一站【镇江中心】'
          },
          {
            state: 0,
            time: '2024年5月2日 上午7:59:24',
            address: '【江苏省无锡市富民路公司】已打包'
          },
          {
            state: 0,
            time: '2024年5月2日 上午6:40:23',
            address: '【江苏省无锡市富民路公司】已收件'
          }
      ],
      info:{},
      keyword:'',
      isEmpty: false,
      loading: false,
    }
  },
  methods: {
    async getSearchData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
       
        const response = await this.$http.get(`https://huaxiangwl.com/api?documentCode=${this.keyword}`);
        
        console.log(response.data[0]['data'][0]['trackDetails']);
        if(response.data[0]['ack']) {

          this.timeAxis = response.data[0]['data'][0]['trackDetails']; // 处理返回的数据
          this.info = response.data[0]['data'][0]
        }
        this.isEmpty = response.data[0]['ack']
        loading.close();
      } catch (error) {
        console.error('请求失败:', error);
        loading.close();
      }
    },
    handleSearch() {
      window.open(`/track?keyword=${this.keyword}`,'_self')
    },
    handleCopy() {
      
      const clipboard = new Clipboard('#copyNode', {
        text: () => this.info.trackingNumber
      });
 
      clipboard.on('success', () => {
        console.log('复制成功');
        this.$message({
          message: '复制成功',
          type:'success'
        })
        // 清理，防止多次触发
        clipboard.destroy();
      });
 
      clipboard.on('error', () => {
        console.log('复制失败');
        // 清理，防止多次触发
        clipboard.destroy();
      })
    }
  },
  mounted() {
    this.keyword = this.$route.query.keyword
    this.getSearchData();
  }
}
</script>

<style lang="scss" scoped>
.container {
  .phone-box {
    height: 50px;
    background: #f7f7f7;
    padding: 0 100px;
    color: #000;
    box-sizing: border-box;
    line-height: 50px;
    font-size: 16px;
  }
  .serach-box {
    margin-bottom: 5px;
    background: #fff;
    padding: 20px 100px;
    color: #000;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 60vh;
    max-height: 70vh;
    .search-left {
      width: 60%;
      overflow: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      .search-header {
        border: 1px solid #e0e0e0;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        .title {
          font: 14px PingFangSC-Regular;
        }
        .left {
          display: flex;
          .sign-icon {
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background: #eaf6ff;
            font-size: 20px;
            margin-right: 10px;
            border-radius: 5px;
          }
        }
        .yqcr-last-event-pc {
          display: flex;
          align-items: center;
        }
      }
      .search-detail {
        border: 1px solid #e0e0e0;
      }
    }
    .search-right {
      width:35%;
      display: flex;
      flex-direction: column;
    }
  }
}
.icon-box {
  display: flex;
  flex-direction: column;
}
/* 时间轴 */
.dynamicFiles {
  position: relative;
  line-height: 36px;
  font: 14px PingFangSC-Regular;
  color: #17233d;
  padding-left: 32px;

  .detail {
    margin-top: 8px;
    padding: 12px 20px;
    background: #eaf6ff;
    border-radius: 12px;
  }

  &:before {
    position: absolute;
    margin-top: 8px;
    left: 4px;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: linear-gradient( 318deg, #cbcfe2 0%, #dfe7f0 100%);
    z-index: 1;
  }
  &:after {
    position: absolute;
    top: 12px;
    left: 8px;
    bottom: -28px;
    content: "";
    width: 1px;
    border-right: 1px solid #dde4ef;
  }
  &:not(:first-child) {
    padding-top: 20px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  &:first-child {
    &:before {
      background: linear-gradient( 180deg, #4eccfe 0%, #3f90fd 100%);
    }
  }
  &:last-child > .detail {
    background: #ffeaea;
  }
}
.commonTitle {
  font: 16px PingFangSC-Regular;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.commonTitle:before{
  content: "";
  width: 6px;
  height: 17px;
  background: #3f90fd;
  position: absolute;
  left: 7px;
  top: 2px;
  border-radius: 9px;
}
.btn {
  width: 100px;
  height: 36px;
  line-height: 36px;
  background: #3f90fd;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.right,.middle {
  font: 14px PingFangSC-Regular;
}
</style>