import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import Track from "../views/track.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/track",
    name: "track",
    component: Track,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
