<template>
  <div class="container">
    <div class="friend-box">
      <div class="title">合作伙伴</div>
      <ul class="friend-list">
        <li v-for="(item, index) in navData" :key="index" class="friend-item">
          <a href="https://www.17track.net/?utm_medium=logistics&utm_source=华翔物流" target="_blank" >
            <img :src="item.url" alt="" style="width: 100%;">
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-box">
      粤ICP备2025387612号
      广州华翔国际物流有限公司
      版权所有
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      navData: [
        {
          url: require('../assets/images/201807091119509695.jpg')
        },
        {
          url: require('../assets/images/201807061256457815.jpg')
        },
        {
          url: require('../assets/images/201807061313488683.jpg')
        },
        {
          url: require('../assets/images/201807061257452782.jpg')
        },
        {
          url: require('../assets/images/201807061258028376.jpg')
        },
        {
          url: require('../assets/images/202201191749011652.jpg')
        },
        {
          url: require('../assets/images/201807061303103332.jpg')
        },
        {
          url: require('../assets/images/201807061303245989.jpg')
        },
        {
          url: require('../assets/images/2020111816216156.jpg')
        },
        {
          url: require('../assets/images/201807061255342674.jpg')
        },
        {
          url: require('../assets/images/17bottom.png')
        },
        {
          url: require('../assets/images/202201171606131680.jpg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  // height: 300px;
  // background-color: #f7f7f7;


  .friend-box {
    height: 300px;
    padding: 0 100px;
    box-sizing: border-box;
    background-color: #fff;

    .title {
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ccc;
      padding: 20px 0 10px;
      margin-bottom: 20px;
    }

    .friend-list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;

      .friend-item {
        width: 16.66666667%;
        height: 45px;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 0px 45px;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }

  }

  .footer-box {
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #454648;
    color: #fff;
    font-size: 16px;
  }
}
</style>